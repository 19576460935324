import { Component } from 'solid-js'
import { Portal } from 'solid-js/web'
import Button from '~/components/button/button'
import { ConfirmationModalProps } from '~/components/confirmation-modal/confirmation-modal.interfaces'
import styles from '~/components/confirmation-modal/confirmation-modal.module.scss'
import DeleteIcon from '~/assets/icons/delete.svg'
import CloseIcon from '~/assets/icons/close.svg'
import { translations } from '~/translations'

const ConfirmationModal: Component<ConfirmationModalProps> = ({ open, 
  setOpen, 
  onConfirm, 
  title, 
  description, 
  type = 'default', 
  rejectLabel, 
  onReject, 
  confirmLabel, 
  confirmIcon: ConfirmIcon, 
  confirmOnly=false
}) => {
  const onClose = () => {
    if (onReject){
      onReject()
    }
    setOpen(false)
  }

  return (
    <>
      {open && (
        <Portal>
          <div class={styles.wrapper}>
            <div class={styles.modal}>
              <button
                class={styles.close}
                onClick={onClose}
                title={translations().general.actions.cancel}
              >
                <CloseIcon class={styles.icon} />
              </button>
              <div class={styles.head}>
                <div class={styles.primaryTitle}>
                  {title}
                </div>
                {description && (
                  <div class={styles.secondaryTitle}>
                    {description}
                  </div>
                )}
              </div>
              <div class={styles.buttons}>
                {!confirmOnly && (
                  <Button onClick={onClose}>
                    {rejectLabel ?? translations().general.actions.cancel}
                  </Button>
                )}
                <Button
                  style={type === 'danger' ? 'negative' : 'lagoon'}
                  onClick={onConfirm}
                  icon={ConfirmIcon ?? DeleteIcon}
                >
                  {confirmLabel ?? translations().general.actions.delete}
                </Button>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default ConfirmationModal